"use client";

import { useEffect } from 'react'
import * as amplitude from '@amplitude/analytics-browser'
import { AnalyticsContext, AnalyticsContextState } from '../analytics-context';
import { glProps } from '@/shared/gl.props';


type AmplitudeAnalyticsProviderProps = glProps.ComponentWithChildrenProps & {
  apiKey: string
}

const amplitudeAnalytics: AnalyticsContextState = {
  setUser: (email: string) => {
    amplitude.setUserId(email);
  },
  trackEvent: (name: string, data: any) => {
    amplitude.track(name, data);
  },
}

export const AmplitudeAnalyticsProvider = ({ apiKey, children }: AmplitudeAnalyticsProviderProps) => {
  useEffect(() => {
    amplitude.init(apiKey)
  }, [apiKey])

  return (
    <AnalyticsContext.Provider value={amplitudeAnalytics}>
      {children}
    </AnalyticsContext.Provider>
  )
}
