'use client';

import { store } from '@/store/store';
import { Provider } from 'react-redux';

import { glProps } from '@/shared/gl.props';

export function StoreProvider({ children }: glProps.ComponentWithChildrenProps) {
  return <Provider store={store}>{children}</Provider>;
}
