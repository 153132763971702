import { ICommonTypes } from '@/shared/types/dictionary';
import GetStarted from '@/public/main-page/Get-started.png';

export default function GetStartedComponent({ data }: { data: ICommonTypes }) {
  return (
    <section className="py-20">
      <div className="w-full">
        <div
          className="relative w-4/5 mx-auto h-[330px] bg-cover bg-center rounded-xl"
          style={{ backgroundImage: `url(${GetStarted.src})` }}
        >
          <div className="absolute left-0 top-1/2 transform -translate-y-1/2 pl-8">
            <p className="font-bold text-white text-5xl">{data.title}</p>
          </div>
        </div>
      </div>
    </section>
  );
}
