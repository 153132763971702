import { createContext } from 'react';
import { createContextHook } from './hooks/create-context.hook';

export type AnalyticsContextState = {
  setUser: (id: string) => void
  trackEvent: (name: string, data?: Record<string, any>) => void
}

export const AnalyticsContext = createContext<AnalyticsContextState | undefined>(undefined);

export const useAnalytics = createContextHook(AnalyticsContext, 'AnalyticsContext');
