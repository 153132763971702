'use client';

import { ENavigationKeys } from '@/navigation/enums/navigation-keys.enum';
import { PropsWithChildren, useEffect, useState } from 'react';

import { Navbar } from '@/components/homepage';
import { Footer } from '@/components/common';
import { getDictionary } from '@/shared/lib/getDictionary';
import { IHomeDictionary } from '@/shared/types/dictionary';
import { TNavItemWithChildren } from '@/navigation/types/nav-item.type';
import { GlRoutes } from '@/navigation';

import { StoreProvider } from '@/providers/store-provider';
import { ServerErrorWrapper } from '@/providers/server-error.provider';
import { AnalyticsProvider } from '@/providers/analytics/providers/analytics.provider';
import { PageTracker } from '@/providers/analytics/trackers/page.tracker';

interface IProps extends PropsWithChildren {
  params: {
    lang: string;
  };
}

export default function ExternalLayout({ params, children }: IProps) {
  const [dictionary, setDictionary] = useState<IHomeDictionary | null>(null);

  useEffect(() => {
    const fetchDictionary = async () => {
      const locale = params.lang;
      const fetchedDictionary = await getDictionary(locale);
      setDictionary(fetchedDictionary.homepage);
    };

    fetchDictionary();
  }, [params.lang]);

  useEffect(() => {
    GlRoutes.adjustPathname(`/${params.lang}/`);
  }, [params.lang]);

  const brand = GlRoutes.findRouteByKey(
    ENavigationKeys.Brand,
  ) as TNavItemWithChildren;

  const navbar = GlRoutes.getAllRoutes();

  return (
    <>
      <AnalyticsProvider>
        <PageTracker />
        <StoreProvider>
          <ServerErrorWrapper>
            {dictionary && (
              <>
                <Navbar brand={brand} navBar={dictionary.navBar} />
                <div style={{ backgroundColor: '#F9F9F9' }}>{children}</div>
                <Footer navbar={navbar} data={dictionary.footer} />
              </>
            )}
          </ServerErrorWrapper>
        </StoreProvider>
      </AnalyticsProvider>
    </>
  );
}
