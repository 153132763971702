import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from '../customFetchBase';
import { IApiPagination, IApiResponse } from '@/shared/types/app';
import { ISubscription } from './interfaces/subscription.interface';
import { ESubscription } from './enums/subscription.enum';
import { EWallet } from '../wallet/enums/wallet.enum';
import {ISubscriptionPage} from "@/app/[lang]/(internal)/portal/billing/plans/interfaces/subscription-page.interfaces";

export const subscriptionApi = createApi({
  reducerPath: 'subscriptionApi',
  baseQuery: customFetchBase,
  tagTypes: [
    ESubscription.TagsType.Subscription,
    EWallet.TagsType.PayInInvoice,
    EWallet.TagsType.GroupedPayInInvoice,
  ],
  endpoints: build => ({
    getSubscriptionPlans: build.query<ISubscription.Subscription[], null>({
      query() {
        return {
          url: '/b2b-offerings/unauthorized-subscription-plans',
          method: 'GET',
        };
      },
      transformResponse: (
        result: IApiResponse<IApiPagination<ISubscription.Subscription>>,
      ) => {
        const { data } = result;

        return data.items;
      },
      providesTags: [ESubscription.TagsType.Subscription],
    }),
    getCurrentSubscriptionPlan: build.query<ISubscription.Offer, null>({
      query() {
        return {
          url: '/b2b-offerings/offers/get-current-subscription-plan',
          method: 'GET',
        };
      },
      transformResponse: (result: IApiResponse<ISubscription.Offer>) => {
        return result.data;
      },
      providesTags: [ESubscription.TagsType.Subscription],
    }),
    invoiceToBuyPlan: build.mutation<ISubscriptionPage.PaymentResponse, ISubscription.PPaymentLink>({
      query: data => ({
        url: '/b2b-offerings/offers/get-invoice-and-link-to-confirm-subscription-plan-purchase',
        method: 'POST',
        body: data,
      }),
      transformResponse: (result: IApiResponse<ISubscriptionPage.PaymentResponse>) => {
        return result.data;
      },
      invalidatesTags: [
        ESubscription.TagsType.Subscription,
        EWallet.TagsType.PayInInvoice,
        EWallet.TagsType.GroupedPayInInvoice,
      ],
    }),
    invoiceToChangePlan: build.mutation<ISubscriptionPage.PaymentResponse, ISubscription.PChangeSubscriptionPlan>({
      query: data => ({
        url: '/b2b-offerings/offers/get-invoice-and-link-to-confirm-subscription-plan-change',
        method: 'POST',
        body: data,
      }),
      transformResponse: (result: IApiResponse<ISubscriptionPage.PaymentResponse>) => {
        return result.data;
      },
      invalidatesTags: [
        ESubscription.TagsType.Subscription,
        EWallet.TagsType.PayInInvoice,
        EWallet.TagsType.GroupedPayInInvoice,
      ],
    }),
    cancelCurrentSubscriptionPlan: build.mutation<
      ISubscription.Subscription,
      null
    >({
      query: () => ({
        url: '/b2b-offerings/offers/cancel-current-subscription-plan',
        method: 'POST',
      }),
      transformResponse: (result: IApiResponse<ISubscription.Subscription>) => {
        return result.data;
      },
      invalidatesTags: [
        ESubscription.TagsType.Subscription,
        EWallet.TagsType.PayInInvoice,
        EWallet.TagsType.GroupedPayInInvoice,
      ],
    }),
    activateFreeSubscriptionPlan: build.mutation<any, null> ({
      query: data => ({
        url: '/b2b-offerings/offers/activate-pending-trial',
        method: 'POST',
        body: { agreedWithTerms: true },
      }),
      transformResponse: (result: IApiResponse<string>) => {
        return result.data;
      },
      invalidatesTags: [
        ESubscription.TagsType.Subscription,
        EWallet.TagsType.PayInInvoice,
        EWallet.TagsType.GroupedPayInInvoice,
      ],
    }),
  }),
});

export const {
  useGetSubscriptionPlansQuery,
  useInvoiceToBuyPlanMutation,
  useInvoiceToChangePlanMutation,
  useCancelCurrentSubscriptionPlanMutation,
  useActivateFreeSubscriptionPlanMutation,
} = subscriptionApi;
