import { useEffect } from 'react';
import { usePathname } from 'next/navigation';
import { useAnalytics } from '@/providers/analytics/analytics-context';

export const PageTracker = () => {
  const { trackEvent } = useAnalytics();
  const pathname = usePathname();

  useEffect(() => {
    trackEvent('visit_page',  { pathname });
  }, [trackEvent]);

  return null;
}
