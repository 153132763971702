import { glProps } from '@/shared/gl.props';

/**
 * Provider
 * */
import { LocalAnalyticsProvider } from '@/providers/analytics/providers/local-analytics.provider';
import { AmplitudeAnalyticsProvider } from '@/providers/analytics/providers/amplitude-analytics.provider';

export const AnalyticsProvider = ({ children }: glProps.ComponentWithChildrenProps) => {
  console.log('AnalyticsProvider');
  if (process.env.APP_ENV === 'production') {
    return (
      <AmplitudeAnalyticsProvider
        apiKey={process.env.AMPLITUDE_KEY || '' }
      >
        {children}
      </AmplitudeAnalyticsProvider>
    )
  }

  return <LocalAnalyticsProvider>{children}</LocalAnalyticsProvider>
}
