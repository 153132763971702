'use client';

import { Button, Modal } from 'flowbite-react';
import React, { useEffect, useState, useRef } from 'react';
import {
  useGetMeQuery,
  useUpdateUserAgreementMutation,
} from '@/store/api/account/account';

export const Agreement: React.FC = () => {
  const [openModal, setOpenModal] = useState(true);
  const [isScrolledToEnd, setIsScrolledToEnd] = useState(false);

  const [updateUserAgreement, { isSuccess }] = useUpdateUserAgreementMutation();
  const { refetch: refetchUser } = useGetMeQuery(null, { skip: false });

  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkContentRef = () => {
      const content = contentRef.current;

      if (content) {
        const handleScroll = () => {
          const { scrollTop, scrollHeight, clientHeight } = content;
          if (scrollTop + clientHeight >= scrollHeight) {
            setIsScrolledToEnd(true);
          } else {
            setIsScrolledToEnd(false);
          }
        };

        content.addEventListener('scroll', handleScroll);

        return () => {
          content.removeEventListener('scroll', handleScroll);
        };
      } else {
        console.log('Content ref is still null');
      }
    };

    const timeoutId = setTimeout(checkContentRef, 0);

    return () => clearTimeout(timeoutId);
  }, [openModal]);

  useEffect(() => {
    const handleUserAgreement = async () => {
      if (isSuccess) {
        try {
          await refetchUser();
          setOpenModal(false);
        } catch (error) {
          console.error('Failed to refetch user data:', error);
        }
      }
    };

    handleUserAgreement();
  }, [isSuccess, refetchUser]);

  return (
    <div className="fixed z-50 inset-0 flex items-center justify-center bg-black bg-opacity-15">
      <Modal show={openModal}>
        <Modal.Header>Terms of Service</Modal.Header>
        <Modal.Body>
          <div ref={contentRef} className="space-y-6 overflow-y-auto max-h-80">
            <div className={'policy-list-wrapper'}>
              <p>
                GolfLive Corp., which is referred to herein as “GolfLive” or
                with first person personal pronouns suchas “we,” “us,” and
                “our,” respects your privacy and is committed to protecting it
                through our compliance with this policy.
              </p>
              <br />
              <h5 className="font-bold">This policy describes:</h5>
              <br />
              <p>
                The types of information we may collect or that you may furnish
                when you install, register, access, or use Homepage ,
                (“website”) and the GolfLive mobile device application (“App”)
                (collectively, the “Services”); and, our practices for
                collecting, using, maintaining, protecting, and disclosing
                information.
              </p>
              <br />
              <h5 className="font-bold">This policy DOES NOT apply to:</h5>
              <br />
              <p>
                Any activities after you leave the website or after you cease
                using the App. This includes information we may collect offline
                or on any other GolfLive apps or websites, including websites
                you may access through the App. Information that you provide to
                us or that is collected by any third party. Please note that,
                after following a link to any third-party website, this policy
                no longer applies, and we recommend that you read the posted
                privacy policy for that website (if any). By accessing or using
                the Services, you agree to this privacy policy. If you do not
                agree to any of these terms, please discontinue your use of the
                Services. Further use is a violation of our rights and
                constitutes a trespass. This policy may change from time to time
                (see Changes to This Policy). Your continued use of our Services
                after we make changes is deemed to be acceptance of those
                changes; so, please check this policy periodically for updates.
              </p>
              <br />
              <h5 className="font-bold">Children&apos;`s Privacy</h5>
              <br />
              <p>
                We do not aim any of our Services directly at children under the
                age of 13, and we do not knowingly collect personal information
                about children under 13. If you are under 13, do not use or
                provide any information about yourself in connection with the
                use of the Services. In fact, if you are under age 13, you
                should have an adult register on your behalf and supervise your
                use of the Services. If we learn we have collected or received
                personal information from a child under 13 without verification
                of parental consent, we will delete that information. If you
                believe we might have any information from or about a child
                under 13, please contact us at privacy@golfliveapp.com.
              </p>
              <br />
              <h5 className="font-bold">
                Information we collect and how we collect it
              </h5>
              <br />
              <p>
                We only collect and use your personal information when we have a
                legitimate reason for doing so. In which instance, we only
                collect personal information that is reasonably necessary to
                provide ourServices to you. We primarily collect this
                information either directly from you when you provide it to us
                or automatically as you navigate through the website or the App.
                We may also collect information about you from third parties,
                including but not limited to our business partners.
              </p>
              <br />
              <p>
                <b>“Voluntarily provided”</b> information refers to any
                information you knowingly and deliberately provide to us when
                using or participating in any of our Services and promotions.{' '}
                <b>“Automatically collected”</b> information refers to any
                information automatically sent by your devices while accessing
                our Services, which may include usage details, IP addresses, and
                information collected through cookies, web beacons, or other
                tracking technologies.
              </p>
              <br />
              <p>
                <b>
                  The information we collect on or through our Services may
                  include:
                </b>
                <br />
                • Information that you provide by filling in forms such as at
                the time of subscribing to our Services including name, postal
                address, email address, date of birth, and telephone number.
                <br />
                • Information relating to when you are posting User Content or
                requesting further Services.
                <br />
                • Information when you enter a contest or promotion sponsored by
                us, and when you report a problem with our Services.
                <br />
                • Records and copies of your correspondence when you contact us
                via email, social media, or on any similar technologies.
                <br />
                • Your responses to surveys that we might ask you to complete.
                <br />
                • Commercial information, such as details of transactions you
                carry out in connection with the Services and the history of
                such transactions.
                <br />
                • Your history of your interactions with your golfing
                professional, (“Pro”) including any correspondence, photos,
                video submissions, and feedback.
                <br />
                • Demographics, such as your age or gender. This category
                includes data that may qualify as protected personal or
                personally identifying information under federal, California, or
                other state laws.
                <br />
                • Geolocation data.
                <br />
                • Employment and education data, such as data you provide when
                you apply for a job.
                <br />• Inferences, such as information about your choices,
                interests, preferences, and favorites.
              </p>
              <br />
              <h5 className="font-bold">
                Information We Collect Automatically.
              </h5>
              <br />
              <p>
                As you navigate through and interact with our Services, we may
                use automatic data collection technologies to collect certain
                information about your equipment, browsing actions, and
                patterns, including:
                <br />
                <b>Usage Details.</b> When you access and use the Website or the
                App, including traffic data, location data, logs, and other
                communication data.
                <br />
                <b>Device Information.</b> We may collect information about your
                mobile device and internet connection, including the device’s
                unique device identifier, IP address, operating system, browser
                type, mobile network information, and the device’s telephone
                number (if any).
                <br />
                <b>Stored Information and Files.</b> The App also may access
                metadata and other information associated with other files
                stored on your device. This may include, for example,
                photographs, audio and video clips, personal contacts, and
                address book information.
                <br />
                We also may use these technologies to collect information about
                your activities over time and across third-party websites, apps,
                or other online services (behavioral tracking). Click here for
                information on how you can opt out of behavioral tracking on or
                through this app and how we respond to browser signals and other
                mechanisms that enable consumers to exercise choice about
                behavioral tracking.
                <br />
                <b>Information Collection Technologies.</b> The technologies we
                use for this automatic data collection may include:
                <br />
                <b>Cookies (or browser cookies).</b> A cookie is a small file
                placed on the hard drive of your computer. You may refuse to
                accept browser cookies by activating the appropriate setting on
                your browser. As noted above, if you select this setting you may
                be unable to access certain parts of our Services. Unless you
                have adjusted your browser setting so that it will refuse
                cookies, our system will issue cookies when you direct your
                browser to our Services.
                <br />
                <b>Web Beacons.</b> Pages of our website and our e-mail messages
                may contain or have attached small electronic files known as web
                beacons (also referred to as clear gifs, pixel tags, and
                singlepixel gifs) that permit GolfLive, for example, to count
                users who have visited those pages or opened an email and for
                other related website statistics (for example, recording the
                popularity of certain website content and verifying system and
                server integrity).
              </p>
              <br />
              <h5 className="font-bold">
                Third-party use of cookies and other tracking technologies
              </h5>
              <br />
              <p>
                Some content or applications, including advertisements, on our
                Services are served/sponsored/maintained by third-parties,
                including advertisers, ad networks and servers, content
                providers, and application providers. These third parties may
                use cookies alone or in conjunction with web beacons or other
                tracking technologies to collect information about you when you
                use our Services. The information they collect may be associated
                with your personal information or they may collect information,
                including personal information, about your online activities
                over time and across different websites and other online
                services.
                <br />
                <br />
                <b>These third parties may include:</b>
                <br />
                • Advertisers, ad networks, and ad servers.
                <br />
                • Analytics companies.
                <br />
                • Your mobile device manufacturer.
                <br />
                • Your mobile service provider.
                <br />
                • They may use this information to provide you with
                interest-based (behavioral) advertising or other targeted
                content.
                <br />
                We do not control these third parties’ tracking technologies or
                how they may be used. If you have any questions about an
                advertisement or other targeted content, you should contact the
                responsible provider directly. For information about how you can
                opt out of receiving targeted advertising from many providers,
                see Choices About How we Use and Disclose Your Information.
              </p>
              <br />
              <h5 className="font-bold">Cookie policy</h5>
              <br />
              <p>
                We use cookies to help improve your experience of our Services.
                This cookie policy is part of GolfLive’s privacy policy. It
                covers the use of cookies between your device and the website.
                We also provide basic information on third-party services that
                we may use, who may also use cookies as part of such third
                party’s service. This policy does not cover cookies of such
                third parties. If you don’t wish to accept cookies from us, you
                should instruct your browser to refuse cookies; however, in such
                a case, we may be unable to provide you with some of your
                desired content and services.
              </p>
              <h5 className="font-bold">What is a cookie?</h5>
              <br />
              <p>
                A cookie is a small piece of data that a website stores on your
                device when you visit. It typically contains information about
                the website itself, a unique identifier that allows the site to
                recognize your web browser when you return, additional data that
                serves the cookie’s purpose, and the lifespan of the cookie
                itself.
                <br />
                Cookies are used to enable certain features (e.g. logging in),
                tracking site usage, storing user settings, and preferences
                (e.g. time zone, notification preferences), and to personalize
                your content (e.g. advertising, language).
                <br />
                Cookies set by the website you are visiting are usually referred
                to as first-party cookies. They typically only track your
                activity on that particular site.
                <br />
                Cookies set by other sites and companies (i.e. third parties)
                are called third-party cookies. They can be used to track you on
                other websites that use the same third-party service.
                <br />
                <br />
                <b>Types of cookies and how we use them:</b>
                <br />
                <br />
                • Essential cookies. Essential cookies are crucial to your
                experience of conducting transactions in the cloud, enabling
                core features like user logins, account management, shopping
                carts, and payment processing. We use essential cookies to
                enable certain functions on our website.
                <br />
                <br />
                • Performance cookies. Performance cookies track how you use a
                website during your visit. Typically, this information is
                anonymous and aggregated, with information tracked across all
                site users. They help companies understand visitor usage
                patterns, identify and diagnose problems or errors their users
                may encounter, and make better strategic decisions in improving
                their audience’s overall website experience. These cookies may
                be set by the website you’re visiting (first-party) or by
                third-party services. They do not collect personal information
                about you. We use performance cookies on the website.
                <br />
                <br />
                • Functionality cookies. Functionality cookies are used to
                collect information about your device and any settings you may
                configure on the website you’re visiting (like language and time
                zone settings). With this information, websites can provide you
                with customized, enhanced, or optimized content and services.
                These cookies may be set by the website you’re visiting
                (first-party) or by third-party services. We use functionality
                cookies for selected features on the website.
                <br />
                <br />• Targeting/advertising cookies. Targeting/advertising
                cookies help determine what promotional content is most relevant
                and appropriate to you and your interests. Websites may use them
                to deliver targeted advertising or limit the number of times you
                see an advertisement. This helps companies improve the
                effectiveness of their campaigns and the quality of content
                presented to you. These cookies may be set by the website you’re
                visiting (first-party) or by third-party services.
                Targeting/advertising cookies set by third-parties may be used
                to track you on other websites that use the same third-party
                service. We use targeting/advertising cookies on the website.
              </p>
              <br />
              <h5 className="font-bold">
                How Long we Keep Your Personal Information:
              </h5>
              <br />
              <p>
                We keep your personal information only for as long as needed.
                This time period may depend on what we are using your
                information for, in accordance with this privacy policy.
                However, if necessary, we may retain your personal information
                for our compliance with a legal, accounting, or reporting
                obligation or for archiving purposes in the public interest,
                scientific, or historical research purposes or statistical
                purposes.
              </p>
              <br />
              <h5 className="font-bold">
                Disclosure of personal information to third parties
              </h5>
              <br />
              <p>
                <b>We may disclose personal information to:</b>
                <br />
                <br />
                • A parent, subsidiary, or affiliate of our company.
                <br />
                <br />
                • Third-party service providers for the purpose of enabling them
                to provide their services, including (without limitation) IT
                service providers, data storage, hosting and server providers,
                ad networks, analytics, error loggers, debt collectors,
                maintenance or problem-solving. providers, advertising
                providers, professional advisors, and payment systems operators
                <br />
                <br />
                • Our employees, contractors, and/or related entities.
                <br />
                <br />
                • Our existing or potential agents or business partners.
                Sponsors or promoters of any competition, sweepstakes, or
                promotion we run.
                <br />
                <br />
                • Credit reporting agencies, courts, tribunals, and regulatory
                authorities, in the event you fail to pay for Services we have
                provided to you.
                <br />
                <br />
                • Courts, tribunals, regulatory authorities, and law enforcement
                officers, as required by law, in connection with any actual or
                prospective legal proceedings, or in order to establish,
                exercise, or defend our legal rights.
                <br />
                <br />
                • Third parties, including agents or sub-contractors, who assist
                us in providing information, Services, or direct marketing to
                you.
                <br />
                <br />
                • Third parties to collect and process data.
                <br />
                <br />• An entity that buys, or to which we transfer all or
                substantially all of our assets and business.
              </p>
              <br />
              <h5 className="font-bold">
                Third parties we currently use include
              </h5>
              <br />
              <p>
                <b>We may disclose personal information to:</b>
                <br />
                <br />
                We use Google Analytics and Google AdSense to analyze the use of
                our Services. They gather information about your website use by
                means of cookies. The information gathered relating to our
                Services is used to create reports about the use of our
                Services. Google’s privacy policy is available at:
                https://policies.google.com/privacy These third parties may use
                cookies alone or in conjunction with web beacons or other
                tracking technologies to collect information about you when you
                use our Services. We do not control these third parties’
                tracking technologies or how they may be used. If you have any
                questions about an advertisement or other targeted content, you
                should contact the responsible provider directly. For
                information about how you can opt out of receiving targeted
                advertising from many providers, see Choices About How We Use
                and Disclose Your Information.
              </p>
              <br />
              <h5 className="font-bold">
                Choices about how we use and disclose your information
              </h5>
              <br />
              <p>
                By providing personal information to us, you understand we will
                collect, hold, use, and disclose your personal information in
                accordance with this privacy policy. You do not have to provide
                personal information to us, however, if you do not, it may
                affect your use of our Services offered on or through it.
                <br />
                <br />• <b>Marketing permission:</b> You may opt-out of allowing
                us to use your personal information for direct marketing
                purposes, you may change your mind at any time by contacting us
                using the details below.
                <br />
                <br />• <b>Non-discrimination:</b> we will not discriminate
                against you for exercising any of your rights over your personal
                information. Unless your personal information is required to
                provide you with a particular service or offer (for example
                providing user support), we will not deny you Services and/or
                charge you different prices or rates for Services, including
                through granting discounts or other benefits, or imposing
                penalties, or provide you with a different level or quality of
                Services.
                <br />
                <br />• <b>Notification of data breaches:</b> we will comply
                with laws applicable to us in respect of any data breach.
                <br />
                <br />• <b>Complaints:</b> If you believe that we have breached
                a relevant data protection law and wish to make a complaint,
                please contact us using the details below and provide us with
                full details of the alleged breach. We will promptly investigate
                your complaint and respond to you, in writing, setting out the
                outcome of our investigation and the steps we will take to deal
                with your complaint. You also have the right to contact a
                regulatory body or data protection authority in relation to your
                complaint.
                <br />
                <br />• <b>Unsubscribe:</b> To unsubscribe from our email
                database or opt-out of communications (including marketing
                communications), please contact us using the details provided in
                this privacy policy or opt-out using the opt-out facilities
                provided in the communication. We may need to request specific
                information from you to help us confirm your identity.
                <br />
                <br />
                We strive to provide you with choices regarding the personal
                information you provide to us. This section describes mechanisms
                we provide for you to control certain uses and disclosures of
                over your information.
                <br />
                <br />• <b>Tracking Technologies:</b> You can set your browser
                to refuse all or some browser cookies, or to alert you when
                cookies are being sent. If you disable or refuse cookies or
                block the use of other tracking technologies, some parts of the
                App may then be inaccessible or not function properly.
                <br />
                <br />• <b>Location Information:</b> You can choose whether to
                allow the App to collect and use real-time information about
                your device’s location through the device’s privacy settings. If
                you block the use of location information, some parts of the App
                may then be inaccessible or not function properly.
                <br />
                <br />• <b>Promotion by the Company:</b> If you do not want us
                to use your email address to promote our own or third parties’
                products or services, you can also always opt-out. You can
                either log into the App and adjusting your user preferences in
                your account profile by checking or by sending us an email or
                text message stating your request to privacy@golfliveapp.com.
                <br />
                <br />• <b>Targeted Advertising by the Company:</b> If you do
                not want us to use information that we collect or that you
                provide to us to deliver advertisements according to our
                advertisers’ target-audience preferences, you can always opt-out
                by adjusting your user advertising preferences in your account
                profile by checking or unchecking the relevant boxes or by
                sending us an email stating your request to
                privacy@golfliveapp.com.
                <br />
                <br />•{' '}
                <b>
                  Disclosure of Your Information for Third-Party Advertising and
                  Marketing:
                </b>{' '}
                If you do not want us to share your personal information with
                unaffiliated or nonagent third parties for advertising and
                marketing purposes, you can opt-out by sending us an email
                stating your request to privacy@golfliveapp.com.
                <br />
                <br />
                We do not control third parties’ collection or use of your
                information to serve interest-based advertising. However, these
                third parties may provide you with ways to choose not to have
                your information collected or used in this way. You can opt out
                of receiving targeted ads from members of the Network
                Advertising Initiative (“NAI”) on the NAI’s website.
              </p>
              <br />
              <h5 className="font-bold">
                Accessing and correcting your personal information
              </h5>
              <br />
              <p>
                You can review and change your personal information by logging
                into the App and visiting your account profile page. You may
                also send us an email at privacy@golfliveapp.com to request
                access to, correct, or delete any personal information that you
                have provided to us. We cannot delete your personal information
                except by also deleting your user account. We may not
                accommodate a request to change information if we believe the
                change would violate any law or legal requirement or cause the
                information to be incorrect.
              </p>
              <br />
              <h5 className="font-bold">
                Additional rights for residents of other states
              </h5>
              <br />
              <p>
                <b>Nevada resident opt-out</b>
                <br />
                <br />
                Nevada residents who wish to exercise their sale opt-out rights
                under Nevada Revised Statutes Chapter 603A may submit a request
                to this designated address:
                https://www.leg.state.nv.us/nrs/nrs-603a.html
                <br />
                <br />
                <b>Your California privacy rights</b>
                <br />
                <br />
                California Civil Code Section § 1798.83 permits users of our
                Services that are California residents to request certain
                information regarding our disclosure of personal information to
                third parties for their direct marketing purposes. To make such
                a request, please send an email to contact@golfliveapp.com.
                <br />
                <br />
                <b>Data security</b>
                <br />
                <br />
                When we collect and process personal information, and while we
                retain this information, we will protect it within commercially
                acceptable means to prevent loss and theft, as well as
                unauthorized access, disclosure, copying, use, or modification.
                Although we will do our best to protect the personal information
                you provide to us, we advise that no method of electronic
                transmission or storage is 100% secure, and no one can guarantee
                absolute data security. You are responsible for selecting any
                password and its overall security strength, ensuring the
                security of your own information within the bounds of our
                Services. For example, ensuring any passwords associated with
                accessing your personal information and accounts are secure and
                confidential. Unfortunately, the transmission of information via
                the internet is not completely secure. Although we do our best
                to protect your personal information, we cannot guarantee the
                security of your personal information transmitted to our
                Services. Any transmission of personal information is at your
                own risk. We are not responsible for circumvention of any
                privacy settings or security measures contained on the Services.
                <br />
                <br />
                <b>Changes to this policy</b>
                <br />
                <br />
                At our discretion, we may change our privacy policy to reflect
                updates to our business processes, current acceptable practices,
                or legislative or regulatory changes. If we decide to change
                this privacy policy, we will post the changes here at the same
                link by which you are accessing this privacy policy. If the
                changes are significant, or if required by applicable law, we
                will contact you (based on your selected preferences for
                communications from us) and all our registered users with the
                new details and links to the updated or changed policy.
              </p>
              <br />
              <h5 className="font-bold">Contact us</h5>
              <br />
              <p>
                For any questions or concerns regarding your privacy, you may
                contact us using the following details: privacy@golfliveapp.com
                7941 Katy Frwy PO Box 327 Houston, Tx 77024 4843-0349-8738.5
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="flex justify-end">
          <Button
            color="success"
            onClick={() => updateUserAgreement()}
            disabled={!isScrolledToEnd}
          >
            I accept
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
