import { IDictionary } from '@/shared/types/dictionary';

const dictionaries = {
  en: () => import('@/dictionaries/en.json').then(module => module.default),
};

export const getDictionary = async (locale: string): Promise<IDictionary> => {
  if (!locale) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return dictionaries['en']();
  } else {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return dictionaries[locale as 'en']();
  }
};
