import { GlRoutes } from '@/navigation';
import { ENavigationKeys } from '@/navigation/enums/navigation-keys.enum';
import { TNavItemWithChildren } from '@/navigation/types/nav-item.type';
import { INavBar } from '@/shared/types/dictionary';
import { Button, Navbar, NavbarBrand } from 'flowbite-react';
import Link from 'next/link';

import { MainLogoSvg } from '@/components/design/assets/svg/index.svg';

interface IProps {
  brand: TNavItemWithChildren;
  navBar: INavBar;
}

export default function NavbarComponent({ brand }: IProps) {
  const signIn = GlRoutes.getRouteHref(ENavigationKeys.SignIn) as string;
  const signUp = GlRoutes.getRouteHref(ENavigationKeys.SignUp) as string;
  const pricingPage = GlRoutes.getRouteHref(
    ENavigationKeys.PrisingPage,
  ) as string;

  return (
    <header className="bg-white border-b-2 lg:px-10 md:px-8 sm:px-6 xs:px-5">
      <Navbar fluid rounded>
        <NavbarBrand href={brand.href}>
          <MainLogoSvg />
        </NavbarBrand>
        <div className="flex justify-between items-center xs:my-3">
          <div className="flex justify-center items-center lg:mr-32 md:mr-24 xs:mr-10">
            <Link
              className="lg:mr-10 md:mr-6 xs:mr-2 bg-white"
              href={pricingPage}
            >
              Pricing
            </Link>
            <Link
              className="xs:mr-2 bg-white"
              href="https://meetings.hubspot.com/john-ballou/coach_connect"
            >
              Book a Demo
            </Link>
          </div>
          <div className="flex justify-center items-center">
            <Link className="mr-3 bg-white" href={signIn}>
              Log in
            </Link>
            <Button color="dark" pill size="sm" href={signUp}>
              Sign up
            </Button>
          </div>
        </div>
      </Navbar>
    </header>
  );
}
