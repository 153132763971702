import { AnalyticsContext, AnalyticsContextState } from '../analytics-context';

import { glProps } from '@/shared/gl.props';

const localAnalytics: AnalyticsContextState = {
  setUser: (id: string) => {
    console.log('Set user for analytics: ', id)
  },
  trackEvent: (name: string, data) => {
    console.log('Track event: ', name, data)
  },
}

export const LocalAnalyticsProvider = ({ children }: glProps.ComponentWithChildrenProps) => {
  return (
    <AnalyticsContext.Provider value={localAnalytics}>
      {children}
    </AnalyticsContext.Provider>
  )
}
