import HeroComponent from '@/components/homepage/components/hero';
import NavbarComponent from '@/components/homepage/components/nav-bar';
import VideoCarousel from '@/components/homepage/components/carousel';
import ScrollContainerComponent from '@/components/homepage/components/scroll-component';
import TextCarouselComponent from '@/components/homepage/components/text-carousel.component';
import GetStartedComponent from '@/components/homepage/components/get-started.component';

export {
  HeroComponent as Hero,
  NavbarComponent as Navbar,
  VideoCarousel as VideoCarousel,
  ScrollContainerComponent as ScrollContainer,
  TextCarouselComponent as TextCarouselComponent,
  GetStartedComponent as GetStartedComponent,
};
