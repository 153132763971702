'use client';

import { useState, useRef, useEffect } from 'react';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import {
  Navigation,
  Pagination,
  Thumbs,
  EffectCoverflow,
} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/thumbs';
import { ICarousel } from '@/shared/types/dictionary';
import { ArrowLeft } from '../../design/assets/svg/video-carousel/arrow-left';
import { ArrowRight } from '../../design/assets/svg/video-carousel/arrow-right';

const slides = [
  {
    src: 'https://www.youtube.com/embed/37gwtSRIrXw?controls=0&showinfo=0&autoplay=1&mute=1&modestbranding=1',
    url: 'https://golf-add-test.s3.us-west-1.amazonaws.com/landing-page/Golf+Live+Instructor_+Meet+Allan+Belden.mp4',
    previewImage:
      'https://cdn.prod.website-files.com/65a18f47e699360f01a1bb34/65aebdf6b1852c8ed4df3977_Oikc08Sw.jpeg',
  },
  {
    src: 'https://www.youtube.com/embed/vumboovWqaI?controls=0&showinfo=0&autoplay=1&mute=1&modestbranding=1',
    url: 'https://golf-add-test.s3.us-west-1.amazonaws.com/landing-page/TrevorSalzman+GolfLiveIntro.mp4',
    previewImage:
      'https://cdn.prod.website-files.com/65a18f47e699360f01a1bb34/65aebe0b61732a745df037f0_Trevor%20Salzman.png',
  },
  {
    src: 'https://www.youtube.com/embed/LyCD_hx7wac?controls=0&showinfo=0&autoplay=1&mute=1&modestbranding=1',
    url: 'https://golf-add-test.s3.us-west-1.amazonaws.com/landing-page/Britt+Sharrock+++Intro.mp4',
    previewImage:
      'https://cdn.prod.website-files.com/65a18f47e699360f01a1bb34/65af5e33a5fd0155dfce9cf8_Screenshot%202024-01-23%20at%2012.35.13%20PM.png',
  },
  {
    src: 'https://www.youtube.com/embed/WP9D0JHh2hc?controls=0&showinfo=0&autoplay=1&mute=1&modestbranding=1',
    url: 'https://golf-add-test.s3.us-west-1.amazonaws.com/landing-page/GolfLive+OJAmaya+BeginnerReel+1.mp4',
    previewImage:
      'https://cdn.prod.website-files.com/65a18f47e699360f01a1bb34/65af592a6238390707ccd53f_OJ%20Amaya%201.jpg',
  },
  {
    src: 'https://www.youtube.com/embed/Ei-WUQS-bZ4?controls=0&showinfo=0&autoplay=1&mute=1&modestbranding=1',
    url: 'https://golf-add-test.s3.us-west-1.amazonaws.com/landing-page/Golf+Live+Instructor_+Meet+Austin+Curtis.mp4',
    previewImage:
      'https://cdn.prod.website-files.com/65a18f47e699360f01a1bb34/65af59340460f0118283a9bc_Austin%20Curtis-straight%20on%201.jpg',
  },
  {
    src: 'https://www.youtube.com/embed/Ei-WUQS-bZ4?controls=0&showinfo=0&autoplay=1&mute=1&modestbranding=1',
    url: 'https://golf-add-test.s3.us-west-1.amazonaws.com/landing-page/KathrynImrie+GolfLiveIntro.mp4',
    previewImage:
      'https://cdn.prod.website-files.com/65a18f47e699360f01a1bb34/65aebe4ccce7132aa07031b3_Kathryn%20Imrie.png',
  },
  {
    src: 'https://www.youtube.com/embed/Ei-WUQS-bZ4?controls=0&showinfo=0&autoplay=1&mute=1&modestbranding=1',
    url: 'https://golf-add-test.s3.us-west-1.amazonaws.com/landing-page/Chris+Como+App+Features.mp4',
    previewImage:
      'https://cdn.prod.website-files.com/65a18f47e699360f01a1bb34/65af5aad96de7b2c9d37cd8c_Como.jpg',
  },
];

export default function VideoCarousel({ data }: { data: ICarousel }) {
  const [thumbsSwiper] = useState<SwiperClass | null>(null);
  const [, setActiveArticleIndex] = useState(0);
  const videoRefs = useRef<(HTMLDivElement | null)[]>([]);
  const articleRefs = useRef<(HTMLDivElement | null)[]>([]);

  const handleSlideChange = (swiper: SwiperClass) => {
    const currentSlideIndex = swiper.realIndex;

    videoRefs.current.forEach((video, index) => {
      const videoElement = video?.querySelector('video');

      if (videoElement) {
        if (index === currentSlideIndex) {
          videoElement.play();
          videoElement.muted = true;
        } else {
          videoElement.pause();
          videoElement.currentTime = 0;
        }
      }
    });
  };

  useEffect(() => {
    const observerOptions = { root: null, rootMargin: '0px', threshold: 0.5 };
    const observerCallback = (entries: IntersectionObserverEntry[]) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const index = articleRefs.current.indexOf(
            entry.target as HTMLDivElement,
          );
          if (index !== -1) {
            setActiveArticleIndex(index);
          }
        }
      });
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions,
    );
    const currentRefs = articleRefs.current;

    if (currentRefs) {
      currentRefs.forEach(ref => {
        if (ref) observer.observe(ref);
      });
    }

    return () => {
      if (currentRefs) {
        currentRefs.forEach(ref => {
          if (ref) observer.unobserve(ref);
        });
      }
    };
  }, []);

  return (
    <section className="pt-16 pb-8 bg-white">
      <div className="relative max-w-screen-xl mx-auto">
        <div className="absolute left-1/2 transform -translate-x-1/2 h-[460px] blur-[172px] rounded-[260px] bg-gradient-to-b from-[rgba(22,126,230,0.50)] to-[rgba(43,196,138,0.50)]"></div>
        <h1 className="text-3xl md:text-4xl lg:text-5xl font-semibold mb-8 text-center">
          {data.title}
        </h1>

        <div className="relative">
          <Swiper
            modules={[Navigation, Pagination, Thumbs, EffectCoverflow]}
            loop={true}
            effect="coverflow"
            pagination={{ clickable: true }}
            centeredSlides={true}
            grabCursor={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            onSlideChange={handleSlideChange}
            coverflowEffect={{
              rotate: 0,
              slideShadows: false,
            }}
            thumbs={{ swiper: thumbsSwiper }}
            className="flex w-full"
            breakpoints={{
              320: { slidesPerView: 1 },
              768: { slidesPerView: 2 },
              1024: { slidesPerView: 3 },
            }}
            navigation={{
              nextEl: '.right',
              prevEl: '.left',
            }}
          >
            {slides.map((slide, index) => (
              <SwiperSlide key={index}>
                <div
                  className="relative w-4/5 md:w-[500px] h-[577px] overflow-hidden mx-auto"
                  ref={el => {
                    videoRefs.current[index] = el;
                  }}
                >
                  <video
                    className="absolute top-0 left-0 w-full h-full object-cover object-center rounded-2xl"
                    src={slide.url}
                    onClick={() => {
                      window.location.href = slide.url;
                    }}
                    muted
                    poster={slide.previewImage}
                    loop
                  ></video>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <button className="left absolute top-1/2 left-4 transform -translate-y-1/2 z-10 w-12 h-12 flex items-center justify-center">
            <ArrowLeft className="w-12 h-12" />
          </button>

          <button className="right absolute top-1/2 right-4 transform -translate-y-1/2 z-10 w-12 h-12 flex items-center justify-center">
            <ArrowRight className="w-12 h-12" />
          </button>
        </div>
      </div>
    </section>
  );
}
