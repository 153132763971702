export default function HeroComponent() {
  return (
    <div className="bg-white py-16">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 grid grid-cols-1 lg:grid-cols-2 gap-8 items-center">
        <div className="text-center lg:text-left flex flex-col items-center lg:items-start">
          <h1 className="text-3xl lg:text-4xl font-bold text-gray-900 mb-4">
            Engage, Coach, and Grow with Golf Live
          </h1>
          <p className="text-base lg:text-lg text-gray-700 mb-6">
            Stay connected with your students and streamline your coaching
            business in one platform.
          </p>
          {/*<button className="bg-black text-white py-2 px-6 rounded-full hover:bg-gray-800 transition-colors">*/}
          {/*  Get started*/}
          {/*</button>*/}
        </div>
        <div className="relative">
          <img
            src="https://cdn.prod.website-files.com/65a18f47e699360f01a1bb0c/65afbaddec6612fa3e1e4876_cover.png"
            alt="Golf app preview"
            className="w-full h-auto object-cover"
          />
        </div>
      </div>
    </div>
  );
}
